//引入Vue
import Vue from 'vue';
import VueRouter from 'vue-router';
import pRoutes from './routesPc';
import mRoutes from './routesMb';

// 判断是否是移动端
let isMobile = () => {
     return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
          navigator.userAgent
     )
}

// 根据设备生成不同路由
let routes = isMobile() ? mRoutes : pRoutes

// 使用插件
Vue.use(VueRouter);

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;

//重写VueRouter.prototype的push方法
VueRouter.prototype.push = function (location, resolve, reject) {
     (resolve && reject) ?
     originPush.call(this, location, resolve, reject): originPush.call(this, location, () => {}, () => {})
}

//重写VueRouter.prototype.replace方法
VueRouter.prototype.replace = function (location, resolve, reject) {
     (resolve && reject) ?
     originReplace.call(this, location, resolve, reject): originReplace.call(this, location, () => {}, () => {});
}


const router = new VueRouter({
     routes,
     scrollBehavior(to, from, savedPosition) {
          console.log(to, from, savedPosition);
          return { y: 0 } // 每次返回再重新进入定位到起始位置
     }
});




export default router;