import Vue from 'vue'
import App from './App.vue'

import router from '@/router';
import "swiper/css/swiper.css";
import * as API from '@/api';

// 全局引入按需引入UI库 vant
import '@/plugins/vant'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import i18n from '@/locale'

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

// 注册'v-animate'  当元素出现在可视范围时添加类名触发动效样式
Vue.directive('animate', {
  inserted: function (el, binding) {
    // 聚焦元素
    binding.addClass = () => {
      const { top } = el.getBoundingClientRect()
      const h = document.documentElement.clientHeight || document.body.clientHeight
      if (top < h) {
        if(el.className.indexOf(binding.value) == -1 ){
          // 初次还未绑定过，则新增类名(注意：下面单引号中间是一个空格！！！)
          el.className = binding.value+' '+el.className
        }
        if (binding.addClass) {
          window.removeEventListener('scroll', binding.addClass)
        }
      }
    }
    window.addEventListener('scroll', binding.addClass,true)
    binding.addClass()
  },
  unbind: function (el, binding) {
    if (binding.addClass) {
      window.removeEventListener('scroll', binding.addClass)
    }
  }
})

//创建Vue类的一个实例
new Vue({
  i18n,
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
    Vue.prototype.$API = API;
  }
}).$mount('#app');


