<template>
  <div>
    <!-- 切换pc和mobile -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      userAgent: navigator.userAgent
    }
  },
};
</script>
<style scoped>
</style>
