const lang = {
  // 顶部导航栏
  header:{
    home:'首页',
    digt:'数字产品',
    idstry:'工业服务',
    paterners: '渠道伙伴',
    about:'关于我们',
  },
  // banner
  banner:{
    desc1: '产业数字化解决方案提供商，助您事业成功',
    desc2: '微粒化解耦数字中台架构，高效打通信息孤岛',
    desc3: '深度融合“OT+IT“技术，简单易用 + 高性价比',
    desc4: '德国工业4.0体系赋能，共建未来工厂竞争优势', 
  },
  // 公司描述栏
  desc:{
    text1:'易代工网络科技成立于2019年，是一家由全球工业隐形冠军企业 \\ 工业互联网资深专家共同参与创立、聚焦工业定制领域的产业数字化服务商。',
    text2:'eMaker关注客户的挑战与压力，基于数字中台技术，为中小制造业提供适用、好用、实惠的一站式数字化解决方案和配套工业服务，帮助企业通过实施全面数智化管理，实现企业的质量、效率和动力变革，助力成为专精特新小巨人企业，开创事业发展新格局。',
  },
  // 数字产品
  digtProd: {
    title:'数字产品',
    ibmp: 'iBPM',
    cockpit: '数字驾驶舱',
    engineer: '数字工程',
    chain: '数字供应链',
    qms: '数字QMS',
    okr: '数字OKR',
  },
  // 工业服务
  idstyServe:{
    title:'工业服务',
    escort:'数字转型陪跑',
    sharing:'数字员工服务',
    factory:'数字工厂服务',
    implementation: '数据治理服务',
  },
  // 中台架构
  arch:{
    title:'微粒化解耦中台架构，一站式数字化解决方案',
  },
  // 服务行业
  serveTrade:{
    title:'服务行业',
    auto:'工业自动化',
    energy:'新能源',
    care: '医疗',
    communication: '通讯',
    car:'汽车',
    rail:'轨道交通',
    trasfrom:'配输电',
    mind:'人工智能',
  },
  // 解决方案
  solution:{
    title:'解决方案',
    wire: '线束线缆',
    board: '电路板',
    pcba: 'PCBA',
    metal: '钣金',
    bar: '铜排 / 母排',
    assembly: '电气组装柜',
    injection: '注塑件',
    materials: '辅材配件'
  },
  // 公司愿景
  value:{
    vision: '我们的愿景',
    visionDesc: '链接每一位合作伙伴的优势和天赋，共筑生机／和谐的智能制造生态系统，让工作和生活更美好。',
    mission: '我们的使命',
    missionDesc: '聚焦客户关注的挑战与压力，提供有竞争力的数字化解决方案和工业配套服务，持续为客户创造最大价值。',
    values: '我们的价值观',
    valuesDesc: '以成就客户为先，以价值创造者为本，坚持开放协作，持续追求卓越。',
  },
  // 联系我们
  contact:{
    title:'联系我们',
    desc1: 'eMaker Solutions 易代工网络科技成立于2019年，是一家由全球隐形冠军代表、工业4.0核心参与者和推进者－德国菲尼克斯和江苏经信智能制造研究院联合战略投资、聚焦工业制造领域的产业数字化服务商。',
    desc2: '平台汇聚制造、工程、运营、人力资源、IT互联网等多领域专家，基于数字中台技术，为中小工业企业提供适用、好用、实惠的一站式数字化解决方案和工业配套服务，帮助企业通过实施全面数智化管理，解决增长、交付、成本、品质、服务、人效、管理协同等核心关键问题，实现企业的质量、效率和动力变革，助力成为专精特新小巨人企业，开创事业发展新格局。',
    email: '公司邮箱',
    emailInfo:'service@emakercn.com',
    phone: '联系电话',
    phoneInfo: '025-66045736',
    address: '公司地址',
    addressInfo:'江苏省南京市江宁开发区苏源大道19号B4座',
    post: '邮政编码',
    postInfo: '210005',
  },
  // 战略伙伴
  partner: {
    title:'战略合作伙伴',
  },
  // 底部栏
  footer: {
    digt: '数字产品',
    digtIbmp: 'iBPM',
    digtCockpit: '数字驾驶舱',
    digtEngineer: '数字工程',
    digtChain: '数字供应链',
    digtQms:'数字QMS',
    serve: '工业服务',
    serveTransform: '数字转型陪跑',
    serveStaff: '数字员工服务',
    serveFactory: '数字工厂服务',
    about: '关于易代工',
    aboutUs: '关于易代工',
    aboutContact: '联系我们',
    copyright: 'Copyright © 2019 eMakerSolutions Network Technology Co., Ltd. All rights reserved.',
    address: '地址：江苏省南京市 江宁开发区苏源大道19号B4座二层',
    record: '苏ICP备2021054721号-1',
  }

}

export default lang
