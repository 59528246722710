export default [
    { // 父路由
    path: '/',
    component: () => import('@/pages/pc/index.vue'),
    redirect: '/index',
    children: [ // 子路由
        // 首页
        {
            path: 'index',
            component: () => import('@/pages/pc/Home/index.vue'),
        },
        // 关于
        {
            path: 'about',
            component: () => import('@/pages/pc/About/index.vue'),
        },
    ]

}, ]