const lang = {
  // 顶部导航栏
  header:{
    home:'Home',
    digt:'Digital Products',
    idstry:'Industrial Services',
    paterners: 'Channel Partners',
    about:'About Us',
  },
  // banner
  banner:{
    desc1: 'Industry digital solutions provider to help your business success.',
    desc2: 'Particle uncoupling digital platform architecture, effectively open up the information island.',
    desc3: 'Micro depth integration of "OT+IT" technology, easy to use + high cost performance.',
    desc4: 'German Industry 4.0 system enables us to build competitive advantages for future factories.', 
  },
  // 公司描述栏
  desc:{
    text1:'Founded in 2019, yidaigong network technology is an industrial digital service provider focusing on the field of industrial customization, which is jointly founded by the global industrial invisible champion, industry 4.0 standard participants, and senior industrial Internet experts.',
    text2: 'Focusing on the challenges and pressures that customers pay attention to, e-maker provides applicable, easy-to-use and affordable one-stop digital solutions and supporting industrial services for small and medium-sized manufacturing industries based on digital midrange technology, helps enterprises realize the quality, efficiency and dynamic transformation of enterprises through the implementation of comprehensive digital intelligence management, helps them become specialized, special and new small giant enterprises, and creates a new pattern of career development.'
  },
  // 数字产品
  digtProd: {
    title:'DIGTAL PRODUCTS',
    ibmp: 'iBPM',
    cockpit: 'Digital Cockpit',
    engineer: 'Digital Engineering',
    chain: 'Digital Supply Chain',
    qms:'Digital QMS',
    okr: 'Digital OKR',
  },
  // 工业服务
  idstyServe:{
    title:'INDUSTRYIAL SERVICE',
    escort:'Digital Transformation Escort Service',
    sharing:'Sharing Digital Employee Services',
    factory:'Shared Digital Factory',
    implementation: 'ERP&MOM Implementation Services',
  },
  // 中台架构
  arch:{
    title:'BUSINESS  ARCHITETURE',
  },
  // 服务行业
  serveTrade:{
    title:'SERVICE  TRADE',
    auto:'Industrial Automation',
    energy:'New Energy',
    care: 'Medical Care',
    communication:'Communication',
    car:'Automobile',
    rail: 'Rail transit',
    trasfrom:'Transmission And Distribution',
    mind:'Artificial Intelligence',
  },
  // 解决方案
  solution:{
    title: 'PROGRAMME',
    wire: 'Harness Cable',
    board: 'Circuit Board',
    pcba: 'PCBA',
    metal: 'Sheet Metal',
    bar: 'Copper Bar / Busbar',
    assembly: 'Electrical Stuffing',
    injection: 'Injection Molded Parts',
    materials: 'Auxiliary Materials'
  },
  // 公司愿景
  value:{
    vision: 'Our Vision',
    visionDesc: 'The plan links the advantages and talents of each partner, builds a vibrant / harmonious intelligent manufacturing ecosystem, and makes work and life better.',
    mission: 'Our Mission',
    missionDesc: 'We focus on the challenges and pressures of customers, provide competitive digital solutions and industrial supporting services, and continue to create maximum value for customers.',
    values: 'Our Values',
    valuesDesc: 'To achieve customer first, to value creator, adhere to open collaboration, continuous pursuit of excellence.',
  },
  // 联系我们
  contact:{
    title:'Contact Us',
    desc1: 'Emaker solutions E-OEM network technology, founded in 2019, is an industrial digital service provider with joint strategic investment from Phoenix Contact and Jiangsu Jingxin Intelligent Manufacturing Research Institute, which is represented by the global invisible champion, the core participant and promoter of industry 4.0, and focuses on the field of industrial manufacturing.',
    desc2: 'The platform gathers experts from many fields such as manufacturing, engineering, operation, human resources, IT and Internet. Based on digital mid-stage technology, IT provides applicable, easy to use and affordable one-stop digital solutions and industrial supporting services for small and medium-sized industrial enterprises, and helps enterprises to implement comprehensive digital intelligent management. Solve the core and key issues of growth, delivery, cost, quality, service, human efficiency, management collaboration and so on, realize the quality, efficiency and dynamic change of the enterprise, help to become a special new small giant enterprise, create a new pattern of career development.',
    email: 'Email',
    emailInfo:'eMakerSolutions@aliyun.com',
    phone: 'Phone',
    phoneInfo: '025-66045736',
    address: 'Adress',
    addressInfo:'Building B4, 19 Suyuan Avenue, Jiangning Development Zone, Nanjing City, Jiangsu Province',
    post: 'Postcode',
    postInfo: '210005',
  },
  // 战略伙伴
  partner: {
    title:'STRATEGIC PARTNER',
  },
  // 底部栏
  footer: {
    digt: 'Digital Products',
    digtIbmp: 'eMaker－iBPM',
    digtCockpit: 'eMaker - Digital cockpit',
    digtEngineer: 'eMaker - Digital engineering',
    digtChain: 'eMaker - Digital supply chain',
    digtQms:'eMaker - Digital QMS',
    serve: 'Industrial Services',
    serveTransform: 'Digital transformation service',
    serveStaff: 'Digital employee services',
    serveFactory: 'Digital factory service',
    about: 'About eMaker',
    aboutUs: 'About eMaker',
    aboutContact: 'Contact us',
    copyright: 'Copyright © 2019 eMakerSolutions Network Technology Co., Ltd. All rights reserved.',
    address: 'Address: 2nd floor, B4, No. 19 Suyuan Avenue, Jiangning Development Zone, Nanjing City, Jiangsu Province',
    record: 'Su ICP Bei No. 2021054721-1',
  }
}

export default lang
