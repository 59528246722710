export default [
    // 重定向
    {
        path: '/m',
        component: () => import('@/pages/mobile/index.vue'),
        redirect:'/m/home',
        children: [
            // 首页
            {
                path: 'home',
                component: () => import('@/pages/mobile/Home/index.vue'),
                meta: {
                    show: true
                },
            },
            // 关于
            {
                path: 'about',
                component: () => import('@/pages/mobile/About/index.vue'),
            },
        ],
    },
    {
        path: '/',
        redirect: '/m',
    },
]